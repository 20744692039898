
/* Styles for the title */
.title {
  font-size: 30px;
  font-weight: bold;
  color: rgb(242, 236, 240);
  margin-bottom: 15px;
  margin-left: 15px;
}

/* Styles for the content container */
.contentContainer {
  flex: 0.8;
  background-color: #000b0b;
  padding: 15px;
  border-radius: 5px;
}

/* Styles for the content text */
.content {
  font-size: 16px;
  color: white;
  line-height: 24px; /* Added line height for better readability */
  margin-bottom: 20px;
}

/* Styles for headings within content */
.heading {
  font-size: 20px;
  font-weight: bold;
  color: rgb(149, 146, 146);
  margin-bottom: 10px;
}

/* Styles for bullet items within content, if needed */
.bulletItem {
  font-size: 16px;
  color: white;
  line-height: 24px;
  margin-bottom: 5px; /* space between bullet points */
  margin-left: 26px;
  text-indent: -21px;
}

.bulletItem li {
  list-style-type: none; /* Remove default bullet point */
  padding-left: 20px; /* Add padding for the custom bullet point */ /* Path to your white dot image */
  background-size: 10px; /* Adjust size of the white dot */
  background-repeat: no-repeat; /* Prevent repeating of the image */
  background-position: 0px 50%; /* Position the white dot */
}

.dateOfRevision {
  font-size: 16px;
  color: rgb(104, 101, 101);
  line-height: 20px;
  margin-bottom: 5px; /* space between bullet points */
}
  