/* Styles for the cosntainer */
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* background-color: #1E1E1E; */
  padding: 15px;
  background-image: url('https://storage.googleapis.com/ai-alchemist-models/website/banner_40_mk2.jpg'); /* Replace 'background-image.jpg' with the actual file name */
  background-size: cover; /* Adjust as needed */
  background-position: center; 
}

